'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const Template: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const pathname = usePathname()

  useEffect(() => {
    console.log('pathname - page_view', pathname)
    TagManager?.dataLayer({
      dataLayer: {
        event: 'page_view',
      },
    })
  }, [pathname])

  return <>{children}</>
}

export default Template
